import { isEmpty } from 'lodash';
import { DATA_STANDARDS } from 'utils/Constants';

export const initialState = {
  overall: {
    building_data: [],
    total_buildings: 0,
    status: true,
    loading: true,
    standards: [DATA_STANDARDS.PFI, DATA_STANDARDS.NEN]
  },
  inspection: {
    mission_data: [],
    status: true,
    loading: true,
    totalItem: 0
  },
  inspectionStatistics: {
    total_completed_missions: 0,
    total_missions: 0,
    status: true,
    loading: true
  },
  //----------------------------------BEGIN COST--------------------------
  totalCost: {
    data: [],
    status: true,
    loading: true
  },
  totalCostBySeverity: {
    data: [],
    status: true,
    loading: true
  },
  totalMostFrequentCost: {
    data: [],
    status: true,
    loading: true
  },
  totalLeastFrequentCost: {
    data: [],
    status: true,
    loading: true
  },
  totalCostByBuildingType: {
    data: [],
    status: true,
    loading: true
  },
  //----------------------------------END COST--------------------------
  totalDefect: {
    data: [],
    status: true,
    loading: true
  },
  totalDefectBySeverity: {
    data: [],
    status: true,
    loading: true
  },
  totalMostFrequentDefect: {
    data: [],
    status: true,
    loading: true
  },
  totalLeastFrequentDefect: {
    data: [],
    status: true,
    loading: true
  },
  totalDefectByBuildingType: {
    data: [],
    status: true,
    loading: true
  },
  buildingHighScore: {
    data: [],
    status: true,
    loading: true
  },
  totalDefectByIntensity: {
    data: [],
    status: true,
    loading: true
  },
  facadeOverallScore: {
    data: [],
    status: true,
    loading: true
  },
  totalDefectByConditionScore: {
    data: [],
    status: true,
    loading: true
  },
  totalDefectByExtentScore: {
    data: [],
    status: true,
    loading: true
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    // GET OVER ALL BUILDING DASHBOARD
    case 'GET_OVERALL_REQUEST':
      return { ...state, overall: { ...initialState.overall } };
    case 'GET_OVERALL_SUCCESS':
      return {
        ...state,
        overall: {
          ...state.overall,
          ...action.payload.data,
          loading: false
        }
      };
    case 'GET_OVERALL_ERROR':
      return { ...state, overall: { ...initialState.overall, loading: false } };

    // GET INSPECTION DASHBOARD
    case 'GET_INSPECTION_REQUEST':
      return { ...state, inspection: { ...initialState.inspection } };
    case 'GET_INSPECTION_SUCCESS':
      return {
        ...state,
        inspection: {
          ...state.inspection,
          mission_data: action.payload.data,
          loading: false,
          totalItem: action.payload?.total
        }
      };
    case 'GET_INSPECTION_ERROR':
      return {
        ...state,
        inspection: { ...initialState.inspection, loading: false }
      };
    // GET INSPECTION STATISTICS DASHBOARD
    case 'GET_INSPECTION_STATISTICS_REQUEST':
      return { ...state, inspectionStatistics: { ...initialState.inspectionStatistics } };
    case 'GET_INSPECTION_STATISTICS_SUCCESS':
      return {
        ...state,
        inspectionStatistics: {
          ...state.inspectionStatistics,
          ...action.payload.data,
          loading: false
        }
      };
    case 'GET_INSPECTION_STATISTICS_ERROR':
      return {
        ...state,
        inspectionStatistics: { ...initialState.inspectionStatistics, loading: false }
      };

    //--------------------------THE COST--------------------------------

    //TOTAL COST
    case 'GET_TOTAL_COST_REQUEST':
      return {
        ...state,
        totalCost: { ...initialState.totalCost }
      };
    case 'GET_TOTAL_COST_SUCCESS':
      return {
        ...state,
        totalCost: {
          data: isEmpty(action.payload.data) ? [] : [...action.payload.data],
          loading: false
        }
      };
    case 'GET_TOTAL_COST_ERROR':
      return {
        ...state,
        totalCost: { ...initialState.totalCost, loading: false }
      };

    // TOTAL COST BY SEVERITY

    case 'GET_TOTAL_COST_BY_SEVERITY_REQUEST':
      return {
        ...state,
        totalCostBySeverity: { ...initialState.totalCostBySeverity }
      };
    case 'GET_TOTAL_COST_BY_SEVERITY_SUCCESS':
      return {
        ...state,
        totalCostBySeverity: {
          data: isEmpty(action.payload.data) ? [] : [...action.payload.data],
          loading: false
        }
      };
    case 'GET_TOTAL_COST_BY_SEVERITY_ERROR':
      return {
        ...state,
        totalCostBySeverity: {
          ...initialState.totalCostBySeverity,
          loading: false
        }
      };

    //GET COST BY BUILDING TYPE

    case 'GET_COST_BY_BUILDING_TYPE_REQUEST':
      return {
        ...state,
        totalCostByBuildingType: {
          ...initialState.totalCostByBuildingType
        },
        loading: false
      };
    case 'GET_COST_BY_BUILDING_TYPE_SUCCESS': {
      return {
        ...state,
        totalCostByBuildingType: {
          data: isEmpty(action.payload.data) ? [] : [...action.payload.data],
          status: action.payload.status,
          loading: false
        }
      };
    }
    case 'GET_COST_BY_BUILDING_TYPE_ERROR':
      return {
        ...state,
        totalCostByBuildingType: {
          ...initialState.totalCostByBuildingType,
          loading: false
        }
      };

    // TOTAL MOST FREQUENT COST

    case 'GET_TOTAL_MOST_FREQUENT_COST_REQUEST':
      return {
        ...state,
        totalMostFrequentCost: { ...initialState.totalMostFrequentCost }
      };
    case 'GET_TOTAL_MOST_FREQUENT_COST_SUCCESS':
      return {
        ...state,
        totalMostFrequentCost: {
          data: isEmpty(action.payload.data) ? [] : [...action.payload.data],
          loading: false
        }
      };
    case 'GET_TOTAL_MOST_FREQUENT_COST_ERROR':
      return {
        ...state,
        totalMostFrequentCost: {
          ...initialState.totalMostFrequentCost,
          loading: false
        }
      };

    // TOTAL LEAST FREQUENT COST

    case 'GET_TOTAL_LEAST_FREQUENT_COST_REQUEST':
      return {
        ...state,
        totalLeastFrequentCost: { ...initialState.totalLeastFrequentCost }
      };
    case 'GET_TOTAL_LEAST_FREQUENT_COST_SUCCESS':
      return {
        ...state,
        totalLeastFrequentCost: {
          data: isEmpty(action.payload.data) ? [] : [...action.payload.data],
          loading: false
        }
      };
    case 'GET_TOTAL_LEAST_FREQUENT_COST_ERROR':
      return {
        ...state,
        totalLeastFrequentCost: {
          ...initialState.totalLeastFrequentCost,
          loading: false
        }
      };

    //------------------------- END THE COST ---------------------
    // TOTAL DEFECT
    case 'GET_TOTAL_DEFECT_REQUEST':
      return {
        ...state,
        totalDefect: { ...initialState.totalDefect }
      };
    case 'GET_TOTAL_DEFECT_SUCCESS':
      return {
        ...state,
        totalDefect: {
          data: isEmpty(action.payload.data) ? {} : action.payload.data,
          loading: false
        }
      };
    case 'GET_TOTAL_DEFECT_ERROR':
      return {
        ...state,
        totalDefect: { ...initialState.totalDefect, loading: false }
      };

    //GET DEFECT BY BUILDING TYPE
    case 'GET_DEFECT_BY_BUILDING_TYPE_REQUEST':
      return {
        ...state,
        totalDefectByBuildingType: {
          ...initialState.totalDefectByBuildingType
        },
        loading: false
      };
    case 'GET_DEFECT_BY_BUILDING_TYPE_SUCCESS': {
      return {
        ...state,
        totalDefectByBuildingType: {
          data: isEmpty(action.payload.data) ? [] : [...action.payload.data],
          status: action.payload.status,
          loading: false
        }
      };
    }
    case 'GET_DEFECT_BY_BUILDING_TYPE_ERROR':
      return {
        ...state,
        totalDefectByBuildingType: {
          ...initialState.totalDefectByBuildingType,
          loading: false
        }
      };
    // TOTAL DEFECT BY SEVERITY
    case 'GET_TOTAL_DEFECT_BY_SEVERITY_REQUEST':
      return {
        ...state,
        totalDefectBySeverity: { ...initialState.totalDefectBySeverity }
      };
    case 'GET_TOTAL_DEFECT_BY_SEVERITY_SUCCESS':
      return {
        ...state,
        totalDefectBySeverity: {
          data: isEmpty(action.payload.data) ? [] : [...action.payload.data],
          loading: false
        }
      };
    case 'GET_TOTAL_DEFECT_BY_SEVERITY_ERROR':
      return {
        ...state,
        totalDefectBySeverity: {
          ...initialState.totalDefectBySeverity,
          loading: false
        }
      };
    // TOTAL MOST FREQUENT DEFECT
    case 'GET_TOTAL_MOST_FREQUENT_DEFECT_REQUEST':
      return {
        ...state,
        totalMostFrequentDefect: { ...initialState.totalMostFrequentDefect }
      };
    case 'GET_TOTAL_MOST_FREQUENT_DEFECT_SUCCESS':
      return {
        ...state,
        totalMostFrequentDefect: {
          data: isEmpty(action.payload.data) ? [] : [...action.payload.data],
          loading: false
        }
      };
    case 'GET_TOTAL_MOST_FREQUENT_DEFECT_ERROR':
      return {
        ...state,
        totalMostFrequentDefect: {
          ...initialState.totalMostFrequentDefect,
          loading: false
        }
      };
    // TOTAL LEAST FREQUENT DEFECT
    case 'GET_TOTAL_LEAST_FREQUENT_DEFECT_REQUEST':
      return {
        ...state,
        totalLeastFrequentDefect: { ...initialState.totalLeastFrequentDefect }
      };
    case 'GET_TOTAL_LEAST_FREQUENT_DEFECT_SUCCESS':
      return {
        ...state,
        totalLeastFrequentDefect: {
          data: isEmpty(action.payload.data) ? [] : [...action.payload.data],
          loading: false
        }
      };
    case 'GET_TOTAL_LEAST_FREQUENT_DEFECT_ERROR':
      return {
        ...state,
        totalLeastFrequentDefect: {
          ...initialState.totalLeastFrequentDefect,
          loading: false
        }
      };
    // BUILDING_HIGHT_SCORE
    case 'GET_BUILDING_HIGHT_SCORE_REQUEST':
      return {
        ...state,
        buildingHighScore: { ...initialState.buildingHighScore }
      };
    case 'GET_BUILDING_HIGHT_SCORE_SUCCESS': {
      const data = action.payload.data;
      return {
        ...state,
        buildingHighScore: { data: data, loading: false }
      };
    }
    case 'GET_BUILDING_HIGHT_SCORE_ERROR':
      return {
        ...state,
        buildingHighScore: {
          ...initialState.buildingHighScore,
          loading: false
        }
      };
    // TOTAL_DEFECT_BY_INTENSITY
    case 'GET_TOTAL_DEFECT_BY_INTENSITY_REQUEST':
      return {
        ...state,
        totalDefectByIntensity: { ...initialState.totalDefectByIntensity }
      };
    case 'GET_TOTAL_DEFECT_BY_INTENSITY_SUCCESS': {
      const data = action.payload.data;
      return {
        ...state,
        totalDefectByIntensity: { data: data, loading: false }
      };
    }
    case 'GET_TOTAL_DEFECT_BY_INTENSITY_ERROR':
      return {
        ...state,
        totalDefectByIntensity: {
          ...initialState.totalDefectByIntensity,
          loading: false
        }
      };
    // FACADE_OVERALL_SCORE
    case 'GET_FACADE_OVERALL_SCORE_REQUEST':
      return {
        ...state,
        facadeOverallScore: { ...initialState.facadeOverallScore }
      };
    case 'GET_FACADE_OVERALL_SCORE_SUCCESS': {
      const {
        data,
        total: average,
        // current_month_year: currentMonth,
        from_day: from,
        to_day: to
      } = action.payload;
      return {
        ...state,
        facadeOverallScore: { data: data, loading: false, average, from, to }
      };
    }
    case 'GET_FACADE_OVERALL_SCORE_ERROR':
      return {
        ...state,
        facadeOverallScore: {
          ...initialState.facadeOverallScore,
          loading: false
        }
      };
    // TOTAL_DEFECT_BY_CONDITION_SCORE
    case 'GET_TOTAL_DEFECT_BY_CONDITION_SCORE_REQUEST':
      return {
        ...state,
        totalDefectByConditionScore: {
          ...initialState.totalDefectByConditionScore
        }
      };
    case 'GET_TOTAL_DEFECT_BY_CONDITION_SCORE_SUCCESS': {
      const { data, total: average, current_month_year: currentMonth } = action.payload;
      return {
        ...state,
        totalDefectByConditionScore: {
          data: data,
          loading: false,
          average,
          currentMonth
        }
      };
    }
    case 'GET_TOTAL_DEFECT_BY_CONDITION_SCORE_ERROR':
      return {
        ...state,
        totalDefectByConditionScore: {
          ...initialState.totalDefectByConditionScore,
          loading: false
        }
      };
    // TOTAL_DEFECT_BY_EXTENT_SCORE
    case 'GET_TOTAL_DEFECT_BY_EXTENT_SCORE_REQUEST':
      return {
        ...state,
        totalDefectByExtentScore: { ...initialState.totalDefectByExtentScore }
      };
    case 'GET_TOTAL_DEFECT_BY_EXTENT_SCORE_SUCCESS': {
      const { data, total: average, current_month_year: currentMonth } = action.payload;
      return {
        ...state,
        totalDefectByExtentScore: {
          data: data,
          loading: false,
          average,
          currentMonth
        }
      };
    }
    case 'GET_TOTAL_DEFECT_BY_EXTENT_SCORE_ERROR':
      return {
        ...state,
        totalDefectByExtentScore: {
          ...initialState.totalDefectByExtentScore,
          loading: false
        }
      };
    default:
      return state;
  }
};
