export const initialState = {
  user: {},
  isUpdate: {},
  enableAuth: {},
  verifyAuth: {},
  disableAuth: {},
  isChangePassword: {},
  isReset: {},
  checkToken: {},
  confirmPassword: {},
  register: {},
  contractorPermission: {},
  areas: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_INFO_REQUEST':
      return { ...state, user: { ...initialState.user } };
    case 'GET_USER_INFO_SUCCESS': {
      return { ...state, user: action.payload };
    }
    case 'UPDATE_USER_INFO_REQUEST':
      return { ...state, isUpdate: { ...initialState.isUpdate } };
    case 'UPDATE_USER_INFO_SUCCESS': {
      return { ...state, isUpdate: action.payload };
    }
    case 'ENABLE_AUTH_REQUEST':
      return { ...state, enableAuth: { ...initialState.enableAuth } };
    case 'ENABLE_AUTH_SUCCESS': {
      return { ...state, enableAuth: action.payload };
    }
    case 'VERIFY_AUTH_REQUEST':
      return { ...state, verifyAuth: { ...initialState.verifyAuth } };
    case 'VERIFY_AUTH_SUCCESS': {
      return { ...state, verifyAuth: action.payload };
    }
    case 'DISABLE_AUTH_REQUEST':
      return { ...state, disableAuth: { ...initialState.disableAuth } };
    case 'DISABLE_AUTH_SUCCESS': {
      return { ...state, disableAuth: action.payload };
    }
    case 'UPDATE_PROFILE_STORE':
      return { ...state, user: action.payload };
    case 'CHANGE_PASSWORD_REQUEST':
      return {
        ...state,
        isChangePassword: { ...initialState.isChangePassword }
      };
    case 'CHANGE_PASSWORD_SUCCESS':
      return { ...state, isChangePassword: action.payload };
    case 'RESET_PASSWORD_REQUEST':
      return { ...state, isReset: { ...initialState.isReset } };
    case 'RESET_PASSWORD_SUCCESS':
      return { ...state, isReset: action.payload };
    case 'CHECK_TOKEN_REQUEST':
      return { ...state, checkToken: { ...initialState.checkToken } };
    case 'CHECK_TOKEN_SUCCESS':
      return { ...state, checkToken: action.payload };
    case 'CONFIRM_PASSWORD_REQUEST':
      return { ...state, confirmPassword: { ...initialState.confirmPassword } };
    case 'CONFIRM_PASSWORD_SUCCESS':
      return { ...state, confirmPassword: action.payload };
    case 'REGISTER_REQUEST':
      return { ...state, register: { ...initialState.register } };
    case 'REGISTER_SUCCESS':
      return { ...state, register: action.payload };

    case 'CHECK_ROLE_USER_REQUEST':
      return { ...state, contractorPermission: { ...initialState.contractorPermission } };
    case 'CHECK_ROLE_USER_SUCCESS': {
      return { ...state, contractorPermission: action.payload };
    }
    case 'GET_AREAS_AND_LOCATIONS_REQUEST':
      return { ...state, areas: { ...initialState.areas } };
    case 'GET_AREAS_AND_LOCATIONS_SUCCESS':
      return { ...state, areas: action.payload.data };
    default:
      return state;
  }
};
