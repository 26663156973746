import { Box, Modal, Paper } from '@mui/material';
import DefectDetails from 'Pannalum_360/components/Defects/DefectDetails';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%', // Set the width of the modal
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  outline: 'none',
  overflowY: 'auto' // Enable vertical scrolling
};

const DefectDetailModal = ({ isOpen, defect, onClose }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={modalStyle}>
        <Paper style={{ maxHeight: '100vh', overflowY: 'scroll' }}>
          <DefectDetails defects={defect} />
        </Paper>
      </Box>
    </Modal>
  );
};

export default DefectDetailModal;
