import classNames from 'classnames';
import { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';

const allowedDates = ['2024-09-20', '2024-09-25', '2024-09-30']; //dummy data

const DatePicker = forwardRef(
  (
    {
      label,
      name,
      onChange,
      onBlur,
      value,
      min,
      max,
      errorMessage,
      disabled = false,
      wrapperClassName
    },
    ref
  ) => {
    const showPicker = useCallback((event) => {
      try {
        event.target?.showPicker && event.target.showPicker();
      } catch (error) {
        console.log('error', error);
      }
    }, []);

    return (
      <div className={classNames('form-group', wrapperClassName)}>
        {label && (
          <label className="mr-4" htmlFor={name}>
            {label}
          </label>
        )}

        <input
          ref={ref}
          disabled={disabled}
          min={min}
          max={max}
          id={name}
          type="date"
          value={value}
          className="form-control"
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={(e) => e.keyCode !== TAB_KEY_CODE && e.preventDefault()}
          onBlur={onBlur}
          onFocus={showPicker}
          onClick={showPicker}
        />
        {errorMessage && <Feedback message={errorMessage} />}
      </div>
    );
  }
);

DatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  wrapperClassName: PropTypes.string
};
export default DatePicker;
