import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import ImageZoom from '../ImageZoom/ImageZoom';

const tableCellStyle = {
  borderRight: '1px solid rgba(224, 224, 224, 1)',
  padding: '8px 16px'
};

export default function DefectDetails({ defects }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ ...tableCellStyle, fontWeight: 'bold' }}>ID</TableCell>
              <TableCell style={tableCellStyle}>{defects?.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ ...tableCellStyle, fontWeight: 'bold' }}>Component</TableCell>
              <TableCell style={tableCellStyle}>{defects?.component}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ ...tableCellStyle, fontWeight: 'bold' }}>Sub component</TableCell>
              <TableCell style={tableCellStyle}>{defects?.subcomponent}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ ...tableCellStyle, fontWeight: 'bold' }}>
                Condition Score
              </TableCell>
              <TableCell style={tableCellStyle}>{defects?.conditionScore}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ ...tableCellStyle, fontWeight: 'bold' }}>Description</TableCell>
              <TableCell style={tableCellStyle}>{defects?.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ ...tableCellStyle, fontWeight: 'bold' }}>Extent</TableCell>
              <TableCell style={tableCellStyle}>{defects?.extent}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ ...tableCellStyle, fontWeight: 'bold' }}>Intensity</TableCell>
              <TableCell style={tableCellStyle}>{defects?.intensity}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ ...tableCellStyle, fontWeight: 'bold' }}>Severity</TableCell>
              <TableCell style={tableCellStyle}>{defects?.severity}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ ...tableCellStyle, fontWeight: 'bold' }}>Solution</TableCell>
              <TableCell style={tableCellStyle}>{defects?.solution}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ ...tableCellStyle, fontWeight: 'bold' }}>Image</TableCell>
              <TableCell style={tableCellStyle}>
                {defects?.imageUrl && (
                  <ImageZoom imageUrl={defects?.imageUrl} />
                  // <img src={defects?.imageUrl} alt="Defect Image" width={450} height={250} />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
