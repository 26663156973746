import React from 'react';
import PropTypes from 'prop-types';
// import HtmlTooltipButton from "components/Tooltips/HtmlTooltip";
import { Typography } from '@mui/material';
// import { Tooltip } from "antd";

function CustomHotSpot({ text = '', icon, type }) {
  return (
    // <HtmlTooltipButton
    //   title={
    //     <div style={{ textAlign: "center" }}>
    //       <Typography color="inherit">{text}</Typography>
    //       {type}
    //     </div>
    //   }
    // >
    <div>{icon && <img className="image" width="50" height="50" src={icon} alt="" />}</div>
    // </HtmlTooltipButton>
  );
}

// CustomHotSpot.propTypes = {
//   text: PropTypes.string.isRequired,
//   icon: PropTypes.string.isRequired,
// };

export default CustomHotSpot;
