/**
 * @author Tran Trung
 * @description refactor code and implement redux
 * @copyright 06/06/2019 Kyanon Digital
 */

import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
//Authorize layout
import AdminWarp from 'layout/Admin';
import AuthWrap from 'layout/Auth';
import SassWrap from 'layout/Saas';
//pages
import LoadingFullScreen from 'components/common/LoadingFullScreen';
import AutodeskTokenCatcher from 'pages/AutodeskTokenCatcher';
import LoginPage from 'pages/Login';
import NotFoundPage from 'pages/NotFound';

const DashboardPage = React.lazy(() => import('pages/Dashboard'));
//Mission page
const UploadAssetsPage = React.lazy(() => import('pages/UploadMission'));
const MissionsListPage = React.lazy(() => import('pages/Mission'));
const MissionDashboardPage = React.lazy(() => import('pages/MissionDetail'));
const NENMissionDashboardPage = React.lazy(() => import('pages/NENMissionDetail'));

//Import profile
const ProfilePage = React.lazy(() => import('pages/UserProfile'));
const BillingPage = React.lazy(() => import('pages/Billing'));
const PricingPage = React.lazy(() => import('pages/Pricing'));

// Authorize page
const RequestResetPasswordPage = React.lazy(() => import('pages/ResetPassword'));
const ResetPasswordPage = React.lazy(() => import('pages/ResetPassword/ResetPasswordConfirm'));
const SignUpPage = React.lazy(() => import('pages/Register'));

//Building page
const BuildingsPage = React.lazy(() => import('pages/Building'));
const ProjectsPage = React.lazy(() => import('pages/Project'));
const BuildingDetail = React.lazy(() => import('pages/BuildingDetail'));
const NewBuilding = React.lazy(() => import('pages/NewBuildingPage'));

const AdminstrationPage = React.lazy(() => import('pages/Adminstration'));

import DataProvider from 'Pannalum_360/context/providers/DataProvider';
import ScenceProvider from 'Pannalum_360/context/providers/ScenceProvider';
import InspectionView from 'Pannalum_360/pages/InspectionView';
import ImmersiveView from 'pages/ImmersiveView';

export default function RootRoute() {
  return (
    <Suspense fallback={<LoadingFullScreen />}>
      <Switch>
        <Route exact path="/signin" component={LoginPage} />
        <Route exact path="/registrations" component={SignUpPage} />
        <Route exact path="/pricing" component={PricingPage} />
        <Route exact path="/password/request-reset" component={RequestResetPasswordPage} />
        <Route exact path="/password/reset/key/:uid-:token" component={ResetPasswordPage} />
        <AuthWrap>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            <Route exact path="/profile" component={ProfilePage} />
            <Route exact path="/billing" component={BillingPage} />
            <Route exact path="/dashboard" component={DashboardPage} />
            {/* <Route exact path="/360/:inspectionId" component={InspectionView} /> */}
            <SassWrap>
              <Switch>
                {/* To do ------  */}
                <Route exact path="/administration" component={AdminstrationPage} />
                <Route exact path="/buildings" component={BuildingsPage} />

                <Route exact path="/projects" component={ProjectsPage} />

                <Route exact path="/buildings/:id" component={BuildingDetail} />
                {/* <Route exact path="/inspections/new" component={NewMissionPage} /> */}
                <Route exact path="/inspections" component={MissionsListPage} />
                <Route exact path="/buildings/:id/inspections" component={MissionsListPage} />
                <Route exact path="/inspections/:id/upload" component={UploadAssetsPage} />
                <Route exact path="/inspection/:id" component={MissionDashboardPage} />
                <Route exact path="/inspection-nen/:id" component={NENMissionDashboardPage} />
                <Route exact path="/autodesk" component={AutodeskTokenCatcher} />

                <Route exact path="/360/:inspectionId" component={ImmersiveView}>
                  {/* <DataProvider >
                    <ScenceProvider>
                      <InspectionView />
                    </ScenceProvider>
                  </DataProvider> */}
                </Route>

                <AdminWarp>
                  <Switch>
                    <Route exact path="/buildings-new" component={NewBuilding} />
                    <Route exact path="*" component={NotFoundPage} />
                  </Switch>
                </AdminWarp>
              </Switch>
            </SassWrap>
          </Switch>
        </AuthWrap>
      </Switch>
    </Suspense>
  );
}
