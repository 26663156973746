import { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
import { PlanPlugin } from '@photo-sphere-viewer/plan-plugin';
import { useBoolean } from 'hooks/useBoolean';
import { useCallback, useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import { Viewer } from '@photo-sphere-viewer/core';
import DefectDetailModal from '../DefectDetailModal';
import CreateNewDefectModal from '../CreateNewDefectModal';
import { ControlFunction, ControlCloseSite, ControlLockView, ControlDate } from '../ControlPanel';

const getDefectIcon = (severity) => {
  switch (severity) {
    case 'Safe':
      return '/images/icons/safe.svg';
    case 'Unsafe':
      return '/images/icons/unsafe.svg';
    case 'Require Repair':
      return '/images/icons/require_repair.svg';
    default:
      return '';
  }
};
const getPlugins = (planImage) => {
  return [
    [
      PlanPlugin,
      {
        position: 'top right',
        buttons: {
          reset: false,
          close: false
        },
        bearing: '270deg',
        size: {
          height: planImage.miniHeight + 'px',
          width: planImage.miniWidth + 'px'
        },
        defaultZoom: 0,
        configureLeaflet(map) {
          const imageBounds = [
            [0, 0],
            [planImage.miniHeight, planImage.miniWidth]
          ];

          map.options.crs = L.CRS.Simple;
          L.imageOverlay(planImage.imageSource, imageBounds).addTo(map);

          map.setMaxZoom(2);
          map.setMinZoom(0);
          map.fitBounds(imageBounds);
          map.setMaxBounds(imageBounds);
        }
      }
    ],
    [MarkersPlugin]
  ];
};

const DEFAULT_DATE = '2024-09-09';
const DEFAULT_DISPLAY_DEFECT_DETAIL = {
  open: false,
  defect: null
};
const RATIO = 0.3;
const ARROW_UP = `<div class="hover-arrow-svg">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50" height="50">
                      <path fill-rule="even-odd" fill="white" d="
                          M50,50 m45,0
                          a45,45 0 1,0 -90,0
                          a45,45 0 1,0  90,0

                          M50,50 m38,0
                          a38,38 0 0,1 -76,0
                          a38,38 0 0,1  76,0

                          M50,50 m30,0
                          a30,30 0 1,0 -60,0
                          a30,30 0 1,0  60,0
                          
                          M50,40 m2.5,-2.5
                          l17.5,17.5
                          a 2.5,2.5 0 0 1 -5,5
                          l-15,-15
                          l-15,15
                          a 2.5,2.5 0 0 1 -5,-5
                          l17.5,-17.5
                          a 3.5,3.5 0 0 1 5,0
                      "></path>
                    </svg>
                  </div>`;
const ARROW_DOWN = `<div class="hover-arrow-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50" height="50">
                        <g transform="rotate(180, 50, 50)">
                            <path fill-rule="even-odd" fill="white" d="
                                M50,50 m45,0
                                a45,45 0 1,0 -90,0
                                a45,45 0 1,0  90,0

                                M50,50 m38,0
                                a38,38 0 0,1 -76,0
                                a38,38 0 0,1  76,0

                                M50,50 m30,0
                                a30,30 0 1,0 -60,0
                                a30,30 0 1,0  60,0
                                
                                M50,40 m2.5,-2.5
                                l17.5,17.5
                                a 2.5,2.5 0 0 1 -5,5
                                l-15,-15
                                l-15,15
                                a 2.5,2.5 0 0 1 -5,-5
                                l17.5,-17.5
                                a 3.5,3.5 0 0 1 5,0
                            "></path>
                        </g>
                      </svg>
                    </div>`;

const SyncView = ({ data }) => {
  const [locked, setLock] = useState(false);
  const [isCompare, onCompare, offCompare] = useBoolean(false);
  const [displayDefectDetail, setDisplayDefectDetail] = useState(DEFAULT_DISPLAY_DEFECT_DETAIL);

  const [open, setOpen] = useState(false);
  const viewer1Ref = useRef(null);
  const viewer2Ref = useRef(null);
  const container1Ref = useRef(null);
  const container2Ref = useRef(null);

  const [newMarkerId, setNewMarkerId] = useState('');
  const [currentIndexNodeViewer1, setCurrentIndexNodeViewer1] = useState(0);
  const [currentIndexNodeViewer2, setCurrentIndexNodeViewer2] = useState(0);
  const [currentDateViewer1, setCurrentDateViewer1] = useState(DEFAULT_DATE);
  const [currentDateViewer2, setCurrentDateViewer2] = useState(DEFAULT_DATE);

  const toggleLock = useCallback((nextValue) => {
    if (typeof nextValue !== 'undefined') return setLock(nextValue);
    setLock((prev) => !prev);
  }, []);

  const { images: viewer1Images } = data[currentDateViewer1] || {};
  const planImageViewer1 = {
    imageSource: data[currentDateViewer1]?.floorPlanImage || '',
    width: data[currentDateViewer1]?.floorPlanImageWidth || '',
    height: data[currentDateViewer1]?.floorPlanImageHeight || '',
    miniWidth: data[currentDateViewer1]?.floorPlanImageWidth * RATIO || '',
    miniHeight: data[currentDateViewer1]?.floorPlanImageHeight * RATIO || ''
  };

  const { images: viewer2Images } = data[currentDateViewer2] || {};
  const planImageViewer2 = {
    imageSource: data[currentDateViewer2]?.floorPlanImage || '',
    width: data[currentDateViewer2]?.floorPlanImageWidth || '',
    height: data[currentDateViewer2]?.floorPlanImageHeight || '',
    miniWidth: data[currentDateViewer2]?.floorPlanImageWidth * RATIO || '',
    miniHeight: data[currentDateViewer2]?.floorPlanImageHeight * RATIO || ''
  };

  const syncViewers = (sourceViewer, targetViewer) => {
    if (!locked || !sourceViewer || !targetViewer) return;
    try {
      targetViewer.rotate({
        pitch: sourceViewer.getPosition().pitch,
        yaw: sourceViewer.getPosition().yaw
      });
      targetViewer.zoom(sourceViewer.getZoomLevel());
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleOnOk = (values) => {
    try {
      const currentNodeId = `node-${currentIndexNodeViewer1}`;
      const { nodeMap, hotspots } = detectInformation(viewer1Images, planImageViewer1.miniHeight);
      const currentNode = nodeMap.get(currentNodeId);
      const randomPositionForPlan = {
        x: currentNode.position.x + Math.floor(Math.random() * 60 - 30),
        y: currentNode.position.y + Math.floor(Math.random() * 60 - 30)
      };
      const image = viewer1Images[currentIndexNodeViewer1];
      try {
        const planPlugin = viewer1Ref.current?.getPlugin(PlanPlugin);
        planPlugin.setHotspots(
          hotspots.concat({
            color: 'blue',
            id: `${currentNodeId}-new-hotspot-${Date.now()}`,
            nodeId: currentNodeId,
            coordinates: [randomPositionForPlan.x, randomPositionForPlan.y],
            positionImage: { x: image.x / 2, y: planImageViewer1.height - image.y / 2 }
          })
        );
      } catch (error) {
        console.log('error', error);
      }

      try {
        const planPlugin = viewer2Ref.current?.getPlugin(PlanPlugin);
        const { hotspots } = detectInformation(viewer2Images, planImageViewer2.miniHeight);

        planPlugin.setHotspots(
          hotspots.concat({
            color: 'blue',
            id: `${currentNodeId}-new-hotspot-${Date.now()}`,
            nodeId: currentNodeId,
            coordinates: [randomPositionForPlan.x, randomPositionForPlan.y],
            positionImage: { x: image.x / 2, y: planImageViewer2.height - image.y / 2 }
          })
        );
      } catch (error) {
        console.log('error', error);
      }

      setOpen(false);
      setNewMarkerId('');
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleOnCancel = () => {
    try {
      try {
        if (viewer1Ref.current) {
          const viewer = viewer1Ref.current;
          const markersPlugin = viewer.getPlugin(MarkersPlugin);
          markersPlugin.removeMarker(newMarkerId);
        }
      } catch (error) {
        console.log(error);
      }

      try {
        if (viewer2Ref.current) {
          const viewer = viewer2Ref.current;
          const markersPlugin = viewer.getPlugin(MarkersPlugin);
          markersPlugin.removeMarker(newMarkerId);
        }
      } catch (error) {
        console.log(error);
      }
      setOpen(false);
      setNewMarkerId('');
    } catch (error) {
      console.log(error);
    }
  };

  const detectInformation = useCallback((images, height) => {
    const nodes = [];
    const hotspots = [];
    const nodeMap = new Map();
    const hotspotsMap = new Map();

    images.forEach((image, imageIndex) => {
      const currentNodeId = `node-${imageIndex}`;

      // HOTSPOTS
      hotspotsMap.set(currentNodeId, {
        color: '#0587ff47',
        id: currentNodeId,
        nodeId: currentNodeId,
        coordinates: [image.x * RATIO, height - image.y * RATIO],
        positionImage: { x: image.x * RATIO, y: height - image.y * RATIO }
      });
      image.defect.forEach((defect, index) => {
        const currentHotspotId = `${currentNodeId}-hotspot-${index}`;
        hotspotsMap.set(currentHotspotId, {
          id: currentHotspotId,
          nodeId: currentNodeId,
          coordinates: [defect.x_loc * RATIO, height - defect.y_loc * RATIO],
          color: defect.color,
          size: 22,
          positionImage: { x: image.x * RATIO, y: height - image.y * RATIO },
          data: defect
        });
      });
      // NODES
      nodeMap.set(currentNodeId, {
        id: currentNodeId,
        panorama: image.url,
        thumbnail: image.url,
        name: currentNodeId,
        caption: image.image_name,
        markers: [
          ...image.defect.map((defect, index) => {
            const currentMarkerId = `${currentNodeId}-defect-marker-${index}`;
            return {
              data: defect,
              id: currentMarkerId,
              position: { yaw: defect.yaw + 'deg', pitch: defect.pitch + 'deg' },
              image: getDefectIcon(defect.severity),
              size: { width: 48, height: 48 }
            };
          }),
          ...image.customHotspots.map((hotspot, index) => {
            const currentHotspotId = `${currentNodeId}-hotspot-marker-${index}`;
            return {
              imageIndex: imageIndex,
              id: currentHotspotId,
              html: index === 0 ? ARROW_UP : ARROW_DOWN,
              type: 'navigate',
              transition: index === 0 ? 'next' : 'prev',
              position: { yaw: hotspot.yaw + 'deg', pitch: hotspot.pitch + 'deg' },
              size: { width: 48, height: 48 }
            };
          })
        ],
        position: { x: image.x * RATIO, y: height - image.y * RATIO }
      });
    });

    nodeMap.forEach((value) => {
      nodes.push(value);
    });
    hotspotsMap.forEach((value) => {
      hotspots.push(value);
    });

    return {
      nodes,
      hotspots,
      nodeMap,
      hotspotsMap
    };
  }, []);

  const firstLoadViewer = useCallback((viewer, images, planImageView, currentIndexNode) => {
    const { nodeMap, hotspots } = detectInformation(images, planImageView.miniHeight);
    const markersPlugin = viewer.getPlugin(MarkersPlugin);
    const planPlugin = viewer.getPlugin(PlanPlugin);
    viewer.zoom(0);
    const currentNode = nodeMap.get(`node-${currentIndexNode}`);
    currentNode.markers.forEach((marker) => {
      markersPlugin.addMarker(marker);
    });
    planPlugin?.setHotspots(hotspots); // todo: improve performance plan
    planPlugin?.setCoordinates([currentNode.position.x, currentNode.position.y]);
  }, []);

  const initialEvents = (viewer, images, planImageView, positionViewer) => {
    const { nodeMap, hotspotsMap } = detectInformation(images, planImageView.miniHeight);
    const markersPlugin = viewer.getPlugin(MarkersPlugin);
    const planPlugin = viewer.getPlugin(PlanPlugin);
    // events
    viewer.addEventListener('position-updated', (e) => {
      if (positionViewer === 0) {
        syncViewers(viewer1Ref.current, viewer2Ref.current);
      } else {
        syncViewers(viewer2Ref.current, viewer1Ref.current);
      }
    });
    viewer.addEventListener('zoom-updated', (e) => {
      if (positionViewer === 0) {
        syncViewers(viewer1Ref.current, viewer2Ref.current);
      } else {
        syncViewers(viewer2Ref.current, viewer1Ref.current);
      }
    });
    viewer.addEventListener('dblclick', (e) => {
      const { data } = e;
      const newDefectId = `new-defect-${Date.now()}`;
      markersPlugin.addMarker({
        id: newDefectId,
        position: { yaw: data.yaw, pitch: data.pitch },
        image: '/images/icons/new-defect.svg',
        size: { width: 38, height: 38 }
      });

      try {
        if (viewer2Ref.current) {
          const markersPlugin2 = viewer2Ref.current?.getPlugin(MarkersPlugin);
          markersPlugin2.addMarker({
            id: newDefectId,
            position: { yaw: data.yaw, pitch: data.pitch },
            image: '/images/icons/new-defect.svg',
            size: { width: 38, height: 38 }
          });
        }
      } catch (error) {
        console.log(error);
      }

      setOpen(true);
      setNewMarkerId(newDefectId);
    });

    planPlugin?.addEventListener('select-hotspot', ({ hotspotId }) => {
      try {
        const selectedHotspot = hotspotsMap.get(hotspotId);
        const nextNode = nodeMap.get(selectedHotspot.nodeId);
        markersPlugin.clearMarkers();
        viewer.setPanorama(nextNode.panorama);
        nextNode.markers.forEach((marker) => {
          markersPlugin.addMarker(marker);
        });
        planPlugin.setCoordinates([
          selectedHotspot.positionImage.x,
          selectedHotspot.positionImage.y
        ]);
        if (positionViewer === 0) {
          setCurrentIndexNodeViewer1(selectedHotspot.nodeId.split('-')[1]);
          console.log("selectedHotspot.nodeId.split('-')[1]", selectedHotspot.nodeId.split('-')[1]);
          if (selectedHotspot.data) {
            // select defect
            viewer.rotate({
              pitch: selectedHotspot.data.pitch + 'deg',
              yaw: selectedHotspot.data.yaw + 'deg'
            });
          } else {
            // select position image
            const firstHotspot = hotspotsMap.get(`${selectedHotspot.nodeId}-hotspot-${0}`);
            viewer.rotate({
              pitch: firstHotspot.data.pitch + 'deg',
              yaw: firstHotspot.data.yaw + 'deg'
            });
          }
          toggleLock(false);
          viewer.zoom(0);
        } else {
          setCurrentIndexNodeViewer2(selectedHotspot.nodeId.split('-')[1]);
          if (selectedHotspot.data) {
            // select defect
            viewer.rotate({
              pitch: selectedHotspot.data.pitch + 'deg',
              yaw: selectedHotspot.data.yaw + 'deg'
            });
          } else {
            // select position image
            const firstHotspot = hotspotsMap.get(`${selectedHotspot.nodeId}-hotspot-${0}`);
            viewer.rotate({
              pitch: firstHotspot.data.pitch + 'deg',
              yaw: firstHotspot.data.yaw + 'deg'
            });
          }
          toggleLock(false);
          viewer.zoom(0);
        }
      } catch (error) {
        console.log('error', error);
      }
    });

    markersPlugin.addEventListener('select-marker', (e) => {
      const { marker } = e;
      const defect = marker.config.data || {};
      if (marker.config.type === 'navigate') {
        const isLockNext = marker.config.imageIndex === viewer1Images.length - 1;
        const isLockPrev = marker.config.imageIndex === 0;
        switch (marker.config.transition) {
          case 'next':
            if (isLockNext) return;
            const nextIndex = marker.config.imageIndex + 1;
            const nextNode = nodeMap.get(`node-${nextIndex}`);
            markersPlugin.clearMarkers();
            viewer.setPanorama(nextNode.panorama);
            nextNode.markers.forEach((marker) => {
              markersPlugin.addMarker(marker);
            });
            planPlugin.setCoordinates([nextNode.position.x, nextNode.position.y]);
            if (positionViewer === 0) {
              setCurrentIndexNodeViewer1(nextIndex);
            } else {
              setCurrentIndexNodeViewer2(nextIndex);
            }
            toggleLock(false);
            return;
          case 'prev':
            if (isLockPrev) return;
            const prevIndex = marker.config.imageIndex - 1;
            const prevNode = nodeMap.get(`node-${prevIndex}`);
            markersPlugin.clearMarkers();
            viewer.setPanorama(prevNode.panorama);
            prevNode.markers.forEach((marker) => {
              markersPlugin.addMarker(marker);
            });
            planPlugin.setCoordinates([prevNode.position.x, prevNode.position.y]);
            if (positionViewer === 0) {
              setCurrentIndexNodeViewer1(prevIndex);
            } else {
              setCurrentIndexNodeViewer2(prevIndex);
            }
            toggleLock(false);
            return;
        }
      } else {
        setDisplayDefectDetail({
          open: true,
          defect: defect
        });
      }
    });
  };

  // initial viewer
  useEffect(() => {
    if (container1Ref.current) {
      try {
        const viewer = new Viewer({
          container: container1Ref.current,
          panorama: viewer1Images[currentIndexNodeViewer1].url,
          plugins: getPlugins(planImageViewer1),
          zoomSpeed: 50,
          navbar: ['zoom']
        });

        firstLoadViewer(viewer, viewer1Images, planImageViewer1, currentIndexNodeViewer1);
        initialEvents(viewer, viewer1Images, planImageViewer1, 0);

        viewer1Ref.current = viewer;
      } catch (error) {
        console.log('error', error);
      }
    }

    return () => {
      if (viewer1Ref.current) {
        console.log('destroy view1');
        viewer1Ref.current.destroy();
      }
    };
  }, [viewer1Images, locked]);

  useEffect(() => {
    if (!isCompare) return;
    if (container2Ref.current) {
      try {
        const indexNodeViewer = locked ? currentIndexNodeViewer1 : currentIndexNodeViewer2;
        const viewer = new Viewer({
          container: container2Ref.current,
          panorama: viewer2Images[indexNodeViewer].url,
          plugins: getPlugins(planImageViewer2),
          zoomSpeed: 50,
          navbar: ['zoom']
        });

        firstLoadViewer(viewer, viewer2Images, planImageViewer2, indexNodeViewer);
        initialEvents(viewer, viewer2Images, planImageViewer2, 1);
        viewer2Ref.current = viewer;
      } catch (error) {
        console.log('error', error);
      }
    }

    return () => {
      if (viewer2Ref.current?.destroy) {
        console.log('destroy view2');
        viewer2Ref.current.destroy();
      }
    };
  }, [viewer2Images, locked, isCompare]);

  return (
    <div>
      <div className="d-flex mt-3">
        <DefectDetailModal
          isOpen={displayDefectDetail.open}
          defect={displayDefectDetail.defect}
          onClose={() => setDisplayDefectDetail(DEFAULT_DISPLAY_DEFECT_DETAIL)}
        />

        <div
          ref={container1Ref}
          style={{
            position: 'relative',
            width: isCompare ? '50%' : '100%',
            height: '80vh',
            objectFit: 'contain'
          }}>
          {data[currentDateViewer1] ? (
            <>
              <ControlFunction isCompare={isCompare} turnOnCompare={onCompare} />
              <ControlDate
                value={currentDateViewer1}
                onChange={(value) => setCurrentDateViewer1(value)}
              />
            </>
          ) : (
            <div>Not found data</div>
          )}
        </div>

        <ControlLockView isCompare={isCompare} isLocked={locked} toggleLock={toggleLock} />

        <div
          ref={container2Ref}
          style={{
            width: isCompare ? '50%' : '0%',
            height: '80vh',
            objectFit: 'contain',
            position: 'relative'
          }}>
          {data[currentDateViewer2] ? (
            <>
              <ControlCloseSite isCompare={isCompare} turnOffCompare={offCompare} />
              {isCompare && (
                <ControlDate
                  value={currentDateViewer2}
                  onChange={(value) => setCurrentDateViewer2(value)}
                />
              )}
            </>
          ) : (
            <div>Not found data</div>
          )}
        </div>
      </div>
      <CreateNewDefectModal open={open} onOk={handleOnOk} onCancel={handleOnCancel} />
    </div>
  );
};

export default SyncView;
