import { Loading } from 'components/common/Loading';
import SyncView from 'features/immersive/SyncView';
import { useBoolean } from 'hooks/useBoolean';
import { getInspectionImmersiveViewData } from 'providers/services';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ImmersiveView = () => {
  const { inspectionId: missionGuid } = useParams();
  const [immersiveViewData, setImmersiveViewData] = useState(null);
  const [loading, onLoading, offLoading] = useBoolean(false);

  const handleGetImmersiveData = async (missionGuid) => {
    onLoading();
    try {
      const response = await getInspectionImmersiveViewData(missionGuid);
      setImmersiveViewData(response[0]);
    } catch (error) {
    } finally {
      offLoading();
    }
  };

  useEffect(() => {
    if (!missionGuid) return;
    handleGetImmersiveData(missionGuid);
  }, [missionGuid]);

  return (
    <div>
      <div className="container-fluid">
        <Loading loading={loading}>
          {immersiveViewData && (
            <SyncView
              data={{
                '2024-09-09': immersiveViewData
              }}
            />
          )}
        </Loading>
      </div>
    </div>
  );
};

export default ImmersiveView;
