import env from 'config/env';
import CookieUtil from 'utils/Cookie';

const {
  REACT_APP_AUTODESK_CLIENT_ID,
  REACT_APP_AUTODESK_COOKIE_KEY,
  REACT_APP_AUTODESK_REDIRECT_URL
} = env;

const beforeAuthPageKey = 'before-auth-page';

export const Autodesk = {
  setToken(token, expires) {
    CookieUtil.setCookie(REACT_APP_AUTODESK_COOKIE_KEY, token, expires);
  },
  getToken(prefix) {
    const token = CookieUtil.getCookie(REACT_APP_AUTODESK_COOKIE_KEY);
    if (!token) return '';
    return prefix ? `${prefix} ${token}` : token;
  },
  clearToken() {
    CookieUtil.removeCookie(REACT_APP_AUTODESK_COOKIE_KEY);
  },
  /**
   * function extract data from autodesk redirect uri
   * @returns { Object } return value of uri: { access_token, expires_in, token_type }
   */
  extractToken() {
    let params = {},
      queryString = window.location.hash.substring(1),
      regex = /([^&=]+)=([^&]*)/g,
      m;
    while ((m = regex.exec(queryString))) {
      params[m[1]] = m[2];
    }

    if (params.expires_in !== undefined) {
      params.expires_in = +params.expires_in;
    }
    return params;
  },
  generateAuthUrl() {
    return `https://developer.api.autodesk.com/authentication/v1/authorize?response_type=token&client_id=${REACT_APP_AUTODESK_CLIENT_ID}&redirect_uri=${REACT_APP_AUTODESK_REDIRECT_URL}&scope=data:read`;
  },
  setBeforeAuthPage(fullPathname) {
    localStorage.setItem(beforeAuthPageKey, fullPathname);
  },
  getBeforeAuthPage(defaultPage = '/') {
    const path = localStorage.getItem(beforeAuthPageKey);
    localStorage.removeItem(beforeAuthPageKey);
    return path || defaultPage;
  }
};
