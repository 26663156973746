import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { AppContext } from 'layout/AppContext';

import './style.scss';

export const BaseModal = ({
  isShow = false,
  closePopup = () => {},
  labels = {},
  title,
  header,
  footer,
  children,
  id,
  className = '',
  centered,
  bodyStyle,
  dialogStyle,
  minWidth = 700,
  maxWidth,
  size = 'modal lg'
}) => {
  const layoutContext = useContext(AppContext);
  const popupRef = useRef();
  const config = useMemo(() => {
    return layoutContext.appContext.state.config;
  }, [layoutContext]);
  useEffect(() => {
    const ref = popupRef.current;
    return () => {
      if (ref) {
        ref.click();
      }
    };
  }, []);

  return (
    <div
      className={`base-modal modal fade ${isShow ? 'show' : ''} ${
        centered ? 'center' : ''
      } ${className}`}
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-labelledby={`#${id}-title`}
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
      ref={popupRef}>
      <div
        className={`modal-dialog modal-lg popup-assign ${size}`}
        role="document"
        style={{ minWidth, ...dialogStyle, ...(maxWidth && { maxWidth: maxWidth }) }}>
        <div
          className="modal-content"
          style={{ minWidth, ...(maxWidth && { maxWidth: maxWidth }) }}>
          {header || (
            <div className="modal-header popup-assign-header mb-4">
              <h4 className="modal-title text-center font-weight-bold">{title}</h4>
            </div>
          )}
          <div className="modal-body" style={{ ...bodyStyle }}>
            <div className="container-fluid">{children}</div>
          </div>
          <div className="modal-footer">
            {footer || (
              <button
                className={`btn ${config.btnRoot}`}
                onClick={() => {
                  closePopup();
                }}>
                {labels.btnClose}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseModal;
