export const initialState = {
  list: {
    data: [],
    loading: true
  },
  info: {
    data: [],
    loading: true
  },
  listGroup: {
    data: {},
    loading: true
  },
  isUpdatePermission: {
    data: {},
    loading: true
  },
  isUpdateName: {
    data: {},
    loading: true
  },
  listTemplate: {
    data: {},
    loading: true
  },
  isCreatedGroup: {
    data: {},
    loading: true
  },
  listContractorAndMission: {
    data: {},
    loading: true
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LIST_GROUP_ASSIGNMENT_REQUEST':
      return {
        ...state,
        list: { ...initialState.list }
      };
    case 'GET_LIST_GROUP_ASSIGNMENT_SUCCESS': {
      if (action.payload.data) {
        return {
          ...state,
          list: { ...action.payload }
        };
      }
      return { ...state };
    }
    case 'GET_INFO_GROUP_ASSIGNMENT_REQUEST':
      return {
        ...state,
        info: { ...initialState.info }
      };
    case 'GET_INFO_GROUP_ASSIGNMENT_SUCCESS': {
      if (action.payload.data) {
        return {
          ...state,
          info: { ...action.payload }
        };
      }
      return { ...state };
    }
    case 'GET_LIST_GROUP_REQUEST':
      return {
        ...state,
        listGroup: { ...initialState.listGroup }
      };
    case 'GET_LIST_GROUP_SUCCESS': {
      return { ...state, listGroup: { data: action.payload, loading: false } };
    }
    case 'UPDATE_PERMISSION_GROUP_REQUEST':
      return {
        ...state,
        isUpdatePermission: { ...initialState.isUpdatePermission }
      };
    case 'UPDATE_PERMISSION_GROUP_SUCCESS': {
      return {
        ...state,
        isUpdatePermission: { data: action.payload, loading: false },
        info: { ...action.payload, loading: false }
      };
    }
    case 'UPDATE_NAME_GROUP_REQUEST':
      return {
        ...state,
        isUpdateName: { ...initialState.isUpdateName }
      };
    case 'UPDATE_NAME_GROUP_SUCCESS': {
      return {
        ...state,
        isUpdateName: { data: action.payload, loading: false }
      };
    }
    case 'GET_TEMPLATE_REQUEST':
      return {
        ...state,
        listTemplate: { ...initialState.listTemplate }
      };
    case 'GET_TEMPLATE_SUCCESS': {
      return {
        ...state,
        listTemplate: { data: action.payload, loading: false }
      };
    }
    case 'CREATE_GROUP_REQUEST':
      return {
        ...state,
        isCreatedGroup: { ...initialState.isCreatedGroup }
      };
    case 'CREATE_GROUP_SUCCESS': {
      return {
        ...state,
        isCreatedGroup: { data: action.payload, loading: false }
      };
    }
    case 'GET_LIST_CONTRACTOR_AND_MISSION_REQUEST':
      return {
        ...state,
        listContractorAndMission: { ...initialState.listContractorAndMission }
      };
    case 'GET_LIST_CONTRACTOR_AND_MISSION_SUCCESS': {
      return {
        ...state,
        listContractorAndMission: { data: action.payload, loading: false }
      };
    }
    default:
      return state;
  }
};
