import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createStore from './redux/stores';
// import stores, { history } from "./redux/stores";
// import { ConnectedRouter } from "connected-react-router";
import App from './App';

// ReactDOM.render(
//   <Provider store={stores}>
//     {/* <ConnectedRouter history={history}> */}
//     {/* <App /> */}
//     <div />
//     {/* </ConnectedRouter> */}
//   </Provider>,
//   document.getElementById("root")
// );

const stores = createStore();

ReactDOM.render(
  <Provider store={stores}>
    <App />
  </Provider>,
  document.getElementById('root')
);
