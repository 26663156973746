import React from 'react';
import './style.scss';
import { FlexBox } from '../FlexBox';
import Spinner from 'react-spinner-material';

export const Loading = ({
  loading,
  children,
  spinnerSize = 35,
  spinnerColor = '#333',
  minHeight
}) => {
  return (
    <div className="loading-wrapper">
      <div className={`loading-content ${loading ? 'active' : ''}`} style={{ minHeight }}>
        {children}
      </div>
      {loading && (
        <FlexBox className="loading-overlay" justify="center" align="center">
          <Spinner radius={spinnerSize} color={spinnerColor} width={4} visible={true} />
        </FlexBox>
      )}
    </div>
  );
};
