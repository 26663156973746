/* eslint-disable require-yield */

import merge from 'lodash/merge';
import { put, call } from 'redux-saga/effects';
import AuthStorage from './AuthStorage';
import LangStorage from './LangStorage';
import env from 'config/env';

const API_URL = env.REACT_APP_URL_API;
const STRIPE_URL = env.REACT_APP_URL_STRIPE;

// Rough implementation. Untested.
function timeout(ms, promise) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      reject(new Error('timeout'));
    }, ms);
    promise.then(resolve, reject);
  });
}

export const fetching = (url, option) =>
  timeout(60000, fetch(url, option))
    .then((response) => {
      if (response.status === 401) {
        response.detail = 'Invalid token.';
        return response;
      }
      return response.status === 204 ? {} : response.json();
    })
    .then((json) => {
      if (json.error) {
        throw json.error;
      } else {
        return json;
      }
    })
    .catch(() => {
      return {
        error: 'Request has been timeout. Please wait a few minutes and refresh the page.'
      };
    });

export default function* ({ uri, params = {}, opt = {}, payment, loading = true }) {
  const defaultOption = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': LangStorage.getLang ? LangStorage.getLang : 'en-us'
    }
  };

  if (!uri) {
    return;
  }
  const options = merge(defaultOption, opt);
  //set Token
  if (AuthStorage.loggedIn) {
    options.headers.Authorization = `Token ${AuthStorage.token}`; //FI
  }
  let url = payment ? `${STRIPE_URL}${uri}` : `${API_URL}${uri}`;

  if (params && Object.keys(params).length > 0) {
    if (options && options.method === 'GET') {
      return;
    } else {
      options.body = JSON.stringify(params);
    }
  }

  if (loading) {
    yield put({ type: 'TOGGLE_LOADING' });
  }
  let response;
  try {
    if (process.env.NODE_ENV === 'development') {
      // console.log("==========> call " + url, ", options= ", options);
    }
    response = yield call(fetching, url, options);
  } catch (error) {
    response = { error };
  }
  return response;
}
