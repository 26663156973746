let env = {
  REACT_APP_URL_API: process.env.REACT_APP_URL_API,
  REACT_APP_URL_BACKEND: process.env.REACT_APP_URL_BACKEND,
  REACT_APP_URL_STRIPE: process.env.REACT_APP_URL_STRIPE,
  REACT_APP_SSL: process.env.REACT_APP_SSL,
  REACT_APP_URL_COMMON_DB_API: process.env.REACT_APP_URL_COMMON_DB_API,
  REACT_APP_GA: process.env.REACT_APP_GA,
  REACT_APP_AUTODESK_CLIENT_ID: process.env.REACT_APP_AUTODESK_CLIENT_ID,
  REACT_APP_AUTODESK_APP_NAME: process.env.REACT_APP_AUTODESK_APP_NAME,
  REACT_APP_AUTODESK_REDIRECT_URL: encodeURI(process.env.REACT_APP_AUTODESK_REDIRECT_URL),
  REACT_APP_AUTODESK_COOKIE_KEY: process.env.REACT_APP_AUTODESK_COOKIE_KEY,
  REACT_APP_AUTODESK_API_URL:
    process.env.REACT_APP_AUTODESK_URL || 'https://developer.api.autodesk.com',
  REACT_APP_GOOGLE_MAP_KEY: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
  REACT_APP_IS_MAINTENANCE: process.env.REACT_APP_IS_MAINTENANCE || ''
};

if (process.env.NODE_ENV === 'development') {
  env = {
    ...env
  };
}

export default env;
