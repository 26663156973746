import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import AuthStorage from 'utils/AuthStorage';

//redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';

function mapStateToProps(state) {
  const contractorPermission = get(state, 'user.contractorPermission.data', {});
  return {
    store: {
      user: state.user.user,
      auth: state.auth,
      languageContent: state.languageContent.languageContent.data,
      canAccessProject: Object.values(contractorPermission).some((item) => item === true)
    }
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({}, dispatch)
  };
};

class NavBarMobile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.menu = React.createRef();
    this.overlay = React.createRef();
  }

  state = {
    isShow: false,
    isToken: false
  };

  logout = () => {
    this.handelCloseMenu();
    this.props.handleLogout();
  };

  handleCheckIsAdmin = () => {
    const {
      store: { user = {} }
    } = this.props;
    return AuthStorage.isAdmin || AuthStorage.value.isSuperAdmin;
  };

  handelCloseMenu = () => {
    this.menu.current.classList.remove('kt-header-menu-wrapper--on');
    this.overlay.current.classList.remove('kt-header-menu-wrapper-overlay');
  };

  handelOpenMenu = () => {
    this.menu.current.classList.add('kt-header-menu-wrapper--on');
    this.overlay.current.classList.add('kt-header-menu-wrapper-overlay');
  };

  render() {
    const { languageContent, canAccessProject } = this.props.store;
    const { isToken } = this.props;
    return (
      <>
        <button className="kt-header-mobile__toolbar-toggler" onClick={this.handelOpenMenu}>
          <span></span>
        </button>
        <div ref={this.menu} className="kt-header-menu-wrapper">
          <button className="kt-header-menu-wrapper-close" onClick={this.handelCloseMenu}>
            <i className="fa fa-close" aria-hidden="true" />
          </button>
          <div className="kt-header-menu kt-header-menu-mobile">
            <ul className="kt-menu__nav">
              {isToken ? (
                <>
                  <li className="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel">
                    <Link
                      to="/dashboard"
                      onClick={this.handelCloseMenu}
                      className="kt-menu__link kt-menu__toggle">
                      {languageContent.data.dashboardPage.titlePage}
                    </Link>
                  </li>
                  <li className="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel">
                    <Link
                      to="/inspections"
                      onClick={this.handelCloseMenu}
                      className="kt-menu__link kt-menu__toggle">
                      {languageContent.data && languageContent.data.missionPage.titlePage}
                    </Link>
                  </li>
                  {!AuthStorage.isSuperAdmin && canAccessProject && (
                    <li className="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel">
                      <Link
                        to="/projects"
                        onClick={this.handelCloseMenu}
                        className={`kt-menu__link kt-menu__toggle`}>
                        {languageContent.data?.projectPage?.titlePage || 'Projects'}
                      </Link>
                    </li>
                  )}
                  <li className="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel">
                    <Link
                      to="/buildings"
                      onClick={this.handelCloseMenu}
                      className="kt-menu__link kt-menu__toggle">
                      {languageContent.data.buildingPage.titlePage}
                    </Link>
                  </li>

                  {/* Check permission access Administration page */}
                  {this.handleCheckIsAdmin() && (
                    <li className="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel">
                      <Link
                        to="/administration"
                        onClick={this.handelCloseMenu}
                        className="kt-menu__link kt-menu__toggle">
                        {languageContent.data.administrationPage.titlePage}
                      </Link>
                    </li>
                  )}

                  <li className="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel">
                    <Link
                      to="/profile"
                      onClick={this.handelCloseMenu}
                      className="kt-menu__link kt-menu__toggle">
                      {languageContent.data.profilePage.titlePage}
                    </Link>
                  </li>

                  <li className="kt-menu__item  kt-menu__item--submenu kt-menu__item--rel">
                    <Link
                      onClick={this.logout}
                      to="/signin"
                      className="kt-menu__link kt-menu__toggle">
                      {languageContent.data.textLogout}
                    </Link>
                  </li>
                </>
              ) : (
                <li className="menu-item">
                  <Link to="/signin" className="kt-menu__link kt-menu__toggle">
                    {languageContent.data.loginPage.titlePage}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div
          ref={this.overlay}
          onClick={this.handelCloseMenu}
          // className="kt-header-menu-wrapper-overlay"
        ></div>
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBarMobile));
