import React, { useState, useMemo, useEffect, useCallback } from 'react';
import * as THREE from 'three';
import PropTypes from 'prop-types';
import SceneContext from '../SceneContext';
import { useParams, withRouter } from 'react-router-dom';

import { quat, vec3 } from 'gl-matrix';

export const RAD_TO_DEG = 180 / Math.PI;

export const clamp = (x, min, max) => Math.max(Math.min(x, max), min);

export const circulate = (val, min, max) => {
  const size = Math.abs(max - min);

  if (val < min) {
    const offset = (min - val) % size;
    val = max - offset;
  } else if (val > max) {
    const offset = (val - max) % size;
    val = min + offset;
  }

  return val;
};

function quatToEuler(quaternion) {
  const x = quaternion[0];
  const y = quaternion[1];
  const z = quaternion[2];
  const w = quaternion[3];
  const x2 = x * x;
  const y2 = y * y;
  const z2 = z * z;
  const w2 = w * w;

  const unit = x2 + y2 + z2 + w2;
  const test = x * w - y * z;

  let pitch, yaw;

  if (test > 0.499995 * unit) {
    // singularity at the north pole
    pitch = Math.PI / 2;
    yaw = 2 * Math.atan2(y, x);
  } else if (test < -0.499995 * unit) {
    // singularity at the south pole
    pitch = -Math.PI / 2;
    yaw = -2 * Math.atan2(y, x);
  } else {
    const view = vec3.fromValues(0, 0, 1);
    const up = vec3.fromValues(0, 1, 0);

    vec3.transformQuat(view, view, quaternion);
    vec3.transformQuat(up, up, quaternion);

    const viewXZ = Math.sqrt(view[0] * view[0] + view[2] * view[2]);

    pitch = Math.atan2(-view[1], viewXZ);
    yaw = Math.atan2(view[0], view[2]);
  }

  return {
    pitch: clamp(pitch * RAD_TO_DEG, -90, 90),
    yaw: circulate(yaw * RAD_TO_DEG, 0, 360)
  };
}
const euler = new THREE.Euler();
euler.setFromQuaternion([0.00259412589, -0.101503707, -0.00516582892, 0.994818366]);
// Access Euler angles: euler.x, euler.y, euler.z
// // console.log('verify', quatToEuler([0.0196363582, 0.864380465, 0.000819081394, 0.502454131]));
// console.log('verify', quatToEuler([0.00259412589, -0.101503707, -0.00516582892, 0.994818366]));
// console.log('verify2', euler);

//my first
// convert quaternionToYawPitch (quat) to pitch and yaw
// function quaternionToYawPitch(quat) {
//   if (!quat || quat.length === 0) {
//     return false;
//   }
//   const quaternion = new THREE.Quaternion(quat[0], quat[1], quat[2], quat[3]);
//   const euler = new THREE.Euler().setFromQuaternion(quaternion);
//   const pitch = THREE.MathUtils.radToDeg(euler.x); // Convert to degrees
//   const yaw = THREE.MathUtils.radToDeg(euler.y); // Convert to degrees
//   return { pitch, yaw };
// }

// 2nd
function quaternionToYawPitch(quaternion) {
  // Create a Three.js quaternion from the provided array
  const quat = new THREE.Quaternion().fromArray(quaternion);

  // Convert quaternion to Euler angles using toEuler() method
  const euler = new THREE.Euler().setFromQuaternion(quat, 'YXZ'); // Using YXZ rotation order

  // Extract yaw (heading) and pitch angles
  const yaw = euler.y;
  const pitch = euler.x;

  // Convert radians to degrees
  const yawDeg = THREE.MathUtils.radToDeg(yaw);
  const pitchDeg = THREE.MathUtils.radToDeg(pitch);

  return { yaw: yawDeg, pitch: pitchDeg };
}

// function quaternionToYawPitch(q) {
//   const x = q[0];
//   const y = q[1];
//   const z = q[2];
//   const w = q[3];

//   var phi = Math.atan2(2 * (w * x + y * z), 1 - 2 * (x * x + y * y)),
//     theta = Math.asin(2 * (w * y - z * x)),
//     psi = Math.atan2(2 * (w * z + x * y), 1 - 2 * (y * y + z * z));
//   return { roll: phi, pitch: theta, yaw: psi };
// }

// function yawPitchToQuaternion(yawDeg, pitchDeg) {
//   // Convert yaw and pitch angles to radians
//   const yaw = THREE.MathUtils.degToRad(yawDeg);
//   const pitch = THREE.MathUtils.degToRad(pitchDeg);

//   // Create quaternions for yaw and pitch rotations
//   const qYaw = new THREE.Quaternion().setFromAxisAngle(
//     new THREE.Vector3(0, 1, 0),
//     yaw
//   );
//   const qPitch = new THREE.Quaternion().setFromAxisAngle(
//     new THREE.Vector3(1, 0, 0),
//     pitch
//   );

//   // Combine the quaternions
//   const quaternion = new THREE.Quaternion();
//   quaternion.multiplyQuaternions(qYaw, qPitch);

//   return quaternion.toArray();
// }

// const updateCoordinates = () => {
//   const newPitch = 5.073817497677716;
//   const newYaw = -1.9891389302578775;

//   const pitchRad = (newPitch * Math.PI) / 180;
//   const yawRad = (newYaw * Math.PI) / 180;
//   const newX = Math.cos(yawRad);
//   const newY = Math.sin(pitchRad);

//   return { newX, newY };
// };

// const dataCors = [
//   {
//     img: "360_1924.jpg",
//     cords: [-17.43754243274131, -17.732683072425505],
//   },
// ];
// const val = [-0.24646638287970982, 4.039835573066272];

function ScenceProvider({ children }) {
  // const res = dataCors.map((item) => {
  //   const yawPitch = yawPitchToQuaternion(item?.cords[1], item?.cords[0]);

  //   return {
  //     img: item?.img,
  //     newCords: yawPitch,
  //   };
  // });

  // console.log("yawPitchToQuaternion", yawPitchToQuaternion(val[1], val[0]));
  const [inspectionList, setInspectionList] = useState({});
  const [sceneList, setSceneList] = useState({});
  const [currentScence, setCurrentScence] = useState({});
  const [missionGuid, setMissionGuid] = useState('');
  const [coordinatesList, setCoordinatesList] = useState([]);
  const [allDefects, setAllDefects] = useState([]);
  const [currentUrlParm, setCurrentUrlParm] = useState(null);
  const [filteredSplitView, setFilteredSplitView] = useState({});

  console.log('currentScence', currentScence);

  const images = inspectionList?.images;
  const twoPointAngle = inspectionList?.twoPts_angle;

  // console.log('twoPointAngle', inspectionList?.twoPts_angle);

  useEffect(() => {
    if (inspectionList && inspectionList.siteView) {
      const siteViewImages = inspectionList?.siteView.images;
      if (siteViewImages.length > 0) {
        const basePath = `/images/${currentUrlParm}/sideView`;
        const manipulatedSplitScreenImg = convertImagesToScenesArray(siteViewImages, basePath);

        const findByCurrentScene = manipulatedSplitScreenImg[currentScence?.slug]; // Corrected variable name
        const slac = currentScence?.slug;
        if (findByCurrentScene) {
          setFilteredSplitView(findByCurrentScene);
        }
      }
    }
  }, [currentScence]);

  useEffect(() => {
    if (images) {
      const extractedDefects = images
        .flatMap((item) => item.defect || [])
        .map((defect) => ({
          ...defect
        }));
      setAllDefects(extractedDefects);
    }
  }, [images]);

  const convertImagesToScenesArray = useCallback(
    (imgs, basePath) => {
      const scenesObj = {};
      // Replace this with the actual path to your "image" folder

      for (let i = 0; i < imgs.length; i += 1) {
        const img = imgs[i];

        // eslint-disable-next-line camelcase
        const { url, image_name, quats, hfov, xyz, exif_image_width, exif_image_height, x, y } = img;

        // eslint-disable-next-line camelcase
        const slug = image_name?.replace('.jpg', '');
        // eslint-disable-next-line camelcase
        // const scenePanoImg = `${basePath}/${image_name}`;
        const scenePanoImg = `${url}`;


        const nextIndex = i + 1;
        const nextImage = nextIndex < imgs.length ? imgs[nextIndex] : null;

        const nextImageName = nextImage ? nextImage?.image_name : null;

        // for get pitch and yaw for next image
        // const nextQuats = nextImage ? nextImage?.quats : null;
        // const nextYP = quatsToPitchYaw(nextQuats);

        // Construct the slug using the next image_name (if available) or fallback to the current image_name

        const nextTransition = nextImageName
          ? nextImageName.replace('.jpg', '')
          : // eslint-disable-next-line camelcase
          image_name.replace('.jpg', '');

        // for previous transition
        const prevIndex = i - 1;
        const prevImage = prevIndex < imgs.length ? imgs[prevIndex] : null;
        const prevImageName = prevImage ? prevImage?.image_name : null;

        const preTransition = prevImageName
          ? prevImageName.replace('.jpg', '')
          : // eslint-disable-next-line camelcase
          image_name.replace('.jpg', '');

        const hotspotArray = [
          // {
          //   transition: nextTransition,
          // },
          // {
          //   transition: nextTransition,
          // },
          // {
          //   // pitch: nextYP?.pitch,
          //   // yaw: nextYP?.yaw,
          //   transition: nextTransition,
          //   // type: "custom",
          //   // icon: "/images/icons/exist.svg",
          // },
        ];
        const getDefectIcon = (severity) => {
          switch (severity) {
            case 'Safe':
              return '/images/icons/safe.svg';
            case 'Unsafe':
              return '/images/icons/unsafe.svg';
            case 'Require Repair':
              return '/images/icons/require_repair.svg';
            default:
              return '';
          }
        };
        // Check if "defect" array exists

        // console.log('img', first);

        if (img?.customHotspots && img?.customHotspots.length >= 2) {
          img.customHotspots[0] = {
            ...img.customHotspots[0],
            transition: nextTransition
          }; // Update the first element with the new transition

          img.customHotspots[1] = {
            ...img.customHotspots[1],
            transition: preTransition
          }; // Update the second element with the new transition

          hotspotArray.push(...img.customHotspots); // Push modified customHotspots into hotspotArray
        }
        if (img.defect && img.defect.length > 0) {
          const defectHotspots = img?.defect?.map((defect) => ({
            id: defect?.id,
            type: 'custom',
            icon: getDefectIcon(defect?.severity),
            transition: '', // You can provide a unique transition name
            pitch: defect?.pitch, // You can use properties from the defect object if needed
            yaw: defect?.yaw,
            text: defect?.description,
            severity: defect?.severity
          }));

          hotspotArray.push(...defectHotspots);
        }

        scenesObj[slug] = {
          // eslint-disable-next-line camelcase
          width: exif_image_width,
          // eslint-disable-next-line camelcase
          height: exif_image_height,
          sceneName: slug,
          slug,
          scenePanoImg,
          initPitch: quatToEuler(quats).pitch,
          initYaw: quatToEuler(quats).yaw,
          hfov,
          x_loc: x,
          y_loc: y,
          hotSpotsArr: hotspotArray,
          missionGuid: currentUrlParm
        };
      }
      return scenesObj;
    },
    [currentUrlParm] // when url parms change its check
  );

  // for convert inspection data to scenes array
  useEffect(() => {
    const basePath = `/images/${currentUrlParm}`;
    if (images) {
      const manipulatedScenes = convertImagesToScenesArray(images, basePath);
      setSceneList(manipulatedScenes);
      setCurrentScence(manipulatedScenes[Object.keys(manipulatedScenes)[0]]);
    }
  }, [images, convertImagesToScenesArray]);

  useEffect(() => {
    const coordinatesArray = images?.map((item) => ({
      x: item.x,
      y: item.y,
      id: item?.image_name?.replace('.jpg', '')
    }));
    setCoordinatesList(coordinatesArray);
  }, [images]);

  // Wrap the value in useMemo to memoize the data value
  const contextValue = useMemo(
    () => ({
      inspectionList,
      setInspectionList,
      sceneList,
      currentScence,
      setCurrentScence,
      missionGuid,
      setMissionGuid,
      coordinatesList,
      allDefects,
      setCurrentUrlParm,
      filteredSplitView,
      currentUrlParm
    }),
    [
      inspectionList,
      sceneList,
      currentScence,
      setCurrentScence,
      missionGuid,
      setMissionGuid,
      coordinatesList,
      allDefects,
      setCurrentUrlParm,
      filteredSplitView,
      currentUrlParm
    ]
  );

  return <SceneContext.Provider value={contextValue}>{children}</SceneContext.Provider>;
}

ScenceProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ScenceProvider;
