export const initialState = {
  languageContent: {
    data: {},
    loading: false
  },
  languageList: {
    data: {},
    loading: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LANGUAGE_CONTENT_REQUEST':
      return { ...state, languageContent: { ...initialState.languageContent, loading: true } };
    case 'GET_LANGUAGE_CONTENT_SUCCESS':
      return { ...state, languageContent: { data: action.payload, loading: false } };
    case 'GET_LANGUAGE_CONTENT_ERROR':
      return { ...state, languageContent: { ...initialState.languageContent, loading: false } };
    case 'GET_LANGUAGE_REQUEST':
      return { ...state, languageList: { ...initialState.languageList, loading: true } };
    case 'GET_LANGUAGE_SUCCESS':
      return { ...state, languageList: { data: action.payload, loading: false } };
    case 'GET_LANGUAGE_ERROR':
      return { ...state, languageList: { ...initialState.languageList, loading: false } };

    default:
      return state;
  }
};
