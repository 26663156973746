import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthStorage from 'utils/AuthStorage';
import { getUserProfile } from 'redux/action/user';

function mapStateToProps(state) {
  return {
    store: {
      user: state.user
    }
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        getUserProfile
      },
      dispatch
    )
  };
};

let regexBuildingUpload = /^.*?\b(buildings)\b.*?\b(upload)\b.*?/gm;

class AdminWarp extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  componentDidMount() {
    if (
      (regexBuildingUpload.test(this.props.history.location.pathname) ||
        this.props.history.location.pathname === '/buildings-new') &&
      !AuthStorage.isSuperAdmin
    ) {
      this.props.history.push('/buildings');
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (regexBuildingUpload.test(nextProps.history.location.pathname) ||
        nextProps.history.location.pathname === '/buildings-new') &&
      !AuthStorage.isSuperAdmin
    ) {
      this.props.history.push('/buildings');
    }
  }

  render() {
    const { children } = this.props;
    if (!AuthStorage.loggedIn) {
      return null;
    }
    return children;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminWarp));
