import BaseModal from 'components/Modal/BaseModal';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

const CreateNewDefectModal = ({ open, onCancel, onOk }) => {
  const { register, control, handleSubmit } = useForm({
    defaultValues: {}
  });

  return (
    <BaseModal
      size="modal-xl"
      isShow={open}
      header={
        <div className="modal-header">
          <h5 className="modal-title">Create new defect</h5>
          <button type="button" className="close" aria-label="Close" onClick={onCancel}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      }
      labels={{
        btnClose: 'Close'
      }}
      closePopup={() => onCancel()}>
      <div>
        <form onSubmit={handleSubmit(onOk)}>
          <div>
            <button type="submit" class="btn btn-primary py-2 px-4">
              Submit
            </button>
          </div>
        </form>
      </div>
    </BaseModal>
  );
};

CreateNewDefectModal.propTypes = {
  open: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
export default CreateNewDefectModal;
