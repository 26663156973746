import PropTypes from 'prop-types';

export const ControlCloseSite = ({ isCompare, turnOffCompare }) => {
  return (
    <div
      className="position-absolute"
      style={{
        top: 8,
        left: 8,
        padding: '8px 12px',
        zIndex: 9999,
        borderRadius: 8,
        backgroundColor: 'white'
      }}>
      {isCompare && (
        <div
          title="Close"
          data-placement="left"
          data-toggle="tooltip"
          className="cursor-pointer"
          onClick={turnOffCompare}>
          <i className="fa fa-times" aria-hidden="true" style={{ fontSize: 18 }}></i>
        </div>
      )}
    </div>
  );
};

ControlCloseSite.propTypes = {
  isCompare: PropTypes.bool.isRequired,
  turnOnCompare: PropTypes.func.isRequired
};
