import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
// import {compose} from 'redux';
// import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthStorage from 'utils/AuthStorage';

function mapStateToProps(state) {
  return {
    store: {
      user: state.user
    }
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({}, dispatch)
  };
};

class AuthWrap extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  componentDidMount() {
    const { history } = this.props;
    if (!AuthStorage.loggedIn) {
      history.push('/signin');
    }
  }

  render() {
    const { children } = this.props;
    if (!AuthStorage.loggedIn) {
      return null;
    }
    return children;
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthWrap));
