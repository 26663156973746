export const setCookie = (key, value, exp) => {
  document.cookie = `${key}=${value}; path=/; expires=${exp.toUTCString()};`;
};

export const getCookie = (key) => {
  var name = key + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
};

export const removeCookie = (key) => {
  document.cookie = key + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

const CookieUtil = {
  getCookie,
  setCookie,
  removeCookie
};

export default CookieUtil;
