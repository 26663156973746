import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ControlLockView = ({ isLocked, isCompare, toggleLock }) => {
  if (!isCompare) return null;
  return (
    <div
      className="position-absolute"
      style={{
        left: '50%',
        top: '50%',
        position: 'absolute',
        zIndex: 999,
        transform: 'translate(-50%, -50%)'
      }}>
      <button
        onClick={() => toggleLock()}
        className={classNames('btn', {
          'btn-primary': isLocked
        })}>
        <i className="fa fa-lock" aria-hidden="true"></i>
      </button>
    </div>
  );
};

ControlLockView.propTypes = {
  isCompare: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired
};
