import React from 'react';
import Zoom from 'react-img-zoom';

const ImageZoom = ({ imageUrl }) => {
  const [img, setImg] = React.useState(imageUrl);

  React.useEffect(() => {
    // Update the image source when the imageUrl prop changes and is truthy
    if (imageUrl) {
      setImg(imageUrl);
    }
  }, [imageUrl]);

  return <Zoom key={img} img={img} zoomScale={2} width={700} height={450} transitionTime={0.5} />;
};
export default ImageZoom;
