import axios from 'axios';
import env from 'config/env';
import { Autodesk } from 'utils/Autodesk';

const headerConfig = {
  Authorization: Autodesk.getToken('Bearer') || undefined
};

if (!headerConfig.Authorization) {
  delete headerConfig.Authorization;
}

const fetcher = axios.create({
  baseURL: env.REACT_APP_AUTODESK_API_URL,
  timeout: 30000,
  responseType: 'json',
  headers: headerConfig
});

fetcher.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const httpCode = error?.response?.status;
    if (httpCode === 401) throw new Error('Missing or incorrect authentication credentials');
    throw error;
  }
);

fetcher.updateToken = () => {
  fetcher.defaults.headers.common['Authorization'] = Autodesk.getToken('Bearer');
};

window.fetcher = fetcher;

export default fetcher;
