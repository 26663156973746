import { useState } from 'react';

export const useBoolean = (defaultValue) => {
  const [bool, setBool] = useState(defaultValue);

  const setTrue = () => setBool(true);
  const setFalse = () => setBool(false);
  const toggle = () => setBool((prevBool) => !prevBool);
  return [bool, setTrue, setFalse, toggle];
};
