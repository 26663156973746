import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { AppContext } from 'layout/AppContext';

class Footer extends Component {
  state = {
    isPageSignIn: false
  };

  componentDidMount() {
    // console.log("this is props==>", this.props);
    if (this.props.location.pathname === '/signin') {
      this.setState({ isPageSignIn: true });
    } else {
      this.setState({ isPageSignIn: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname === '/signin') {
      this.setState({ isPageSignIn: true });
    } else {
      this.setState({ isPageSignIn: false });
    }
  }

  render() {
    const { appContext } = this.context;
    const { config } = appContext.state;
    const { isPageSignIn } = this.state;

    const isPageSignInStyle = isPageSignIn
      ? { left: '0px', right: '0px', textAlign: 'center' }
      : {};

    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.h3zoom.ai/contacts"
        className="footer"
        style={{
          ...isPageSignInStyle
        }}>
        {config.baseConfig.logoH3zoom && config.baseConfig.logoH3zoom !== '' && (
          <div
            style={{
              margin: '0 auto',
              height: '50px',
              width: '231px',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${config.baseConfig.logoH3zoom})`
            }}></div>
        )}
      </a>
    );
  }
}

Footer.contextType = AppContext;
export default withRouter(Footer);
