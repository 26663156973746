//Import css files
import '@photo-sphere-viewer/core/index.css';
import '@photo-sphere-viewer/markers-plugin/index.css';
import '@photo-sphere-viewer/plan-plugin/index.css';
import '@photo-sphere-viewer/map-plugin/index.css';
import 'leaflet/dist/leaflet.css';
import 'styles/style.scss';
import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Wrapper from 'layout/Wrapper';
import ReactGA from 'react-ga';
import env from 'config/env';
const REACT_APP_GA = env.REACT_APP_GA;
ReactGA.initialize(REACT_APP_GA);
export default function App() {
  return (
    <Router>
      <Wrapper />
    </Router>
  );
}
