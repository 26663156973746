import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer, { exampleInitialState } from '../reducers';
import rootSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();

// const bindMiddleware = middleware => {
//   if (process.env.NODE_ENV !== "production") {
//     const { composeWithDevTools } = require("redux-devtools-extension");
//     return composeWithDevTools(applyMiddleware(logger, middleware));
//   }
//   return applyMiddleware(...middleware);
// };

export default (state = exampleInitialState) => {
  const middleware = [sagaMiddleware];

  const middlewareEnhancer = applyMiddleware(...middleware);

  const enhancer = [middlewareEnhancer];

  if (process.env.NODE_ENV === 'development') {
    let devTool = window?.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
    if (devTool) {
      enhancer.push(devTool);
      // enhancer.push(logger)
    }
  }
  const composeMiddleware = compose(...enhancer);

  const store = createStore(
    combineReducers(rootReducer),
    state,
    composeMiddleware
    // bindMiddleware([sagaMiddleware])
  );
  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(rootSaga);
  };

  // run the rootSaga initially
  store.runSagaTask();
  return store;
};
