import auth, { initialState as initialAuth } from './auth';
import building, { initialState as initialBuilding } from './building';
import user, { initialState as initUser } from './user';
import isUpdate, { initialState as initIsUpdate } from './user';
import enableAuth, { initialState as initEnableAuth } from './user';
import verifyAuth, { initialState as initVerifyAuth } from './user';
import disableAuth, { initialState as initDisableAuth } from './user';
import dashboard, { initialState as initDashboard } from './dashboard';
import isChangePassword, { initialState as initChangePassword } from './user';
import isReset, { initialState as initIsReset } from './user';
import listUser, { initialState as initListUser } from './manageuser';
import isAddUser, { initialState as initAddUser } from './manageuser';
import isRemoveUser, { initialState as initRemoveUser } from './manageuser';
import listMission, { initialState as initListMission } from './mission';
import missionDetail, { initialState as initMissionDetail } from './mission';
import discardImage, { initialState as initDiscardImage } from './mission';
import checkToken, { initialState as initCheckToken } from './user';
import confirmPassword, { initialState as initConfirmPassword } from './user';
import register, { initialState as initRegister } from './user';
import listMarker, { initialState as initListMarker } from './building';
import { loadingBarReducer } from 'react-redux-loading-bar';
import buildingSummary, { initialState as initBuildingSummary } from './building';
import listYearForFilter, { initialState as initListYear } from './building';
import mapImage, { initialState as initMapImage } from './building';
import elevationBuilding, { initialState as initElevationBuilding } from './building';
import listMissionOfBuilding, { initialState as initMissions } from './building';
import elevationBuildingCompare, { initialState as initElevationCompare } from './building';
import missionSummary, { initialState as initMissionSummary } from './mission';
import statusFile, { initialState as initStatusFile } from './mission';
import missionElevation, { initialState as initMissionElevation } from './mission';
import isUpdateStatus, { initialState as initUpdateStatus } from './mission';
import listBuildingForSelect, { initialState as initListBuildingSelect } from './mission';
import isCreateMission, { initialState as initCreateMission } from './mission';
import contentPage, { initialState as initContentPage } from './contentPage';
import languageContent, { initialState as initLanguageContent } from './contentPage';
import languageList, { initialState as initLanguage } from './contentPage';
import isCreateBuilding, { initialState as initCreateBuilding } from './building';
import isRemoveBuilding, { initialState as initRemoveBuilding } from './building';
import isRemoveMission, { initialState as initRemoveMission } from './mission';
import notifyMission, { initialState as initNotifyMission } from './mission';
import isUploadBuilding, { initialState as initUploadBuilding } from './building';
import formUpload, { initialState as initFormUpload } from './building';
import listOrganization, { initialState as initListOrganization } from './admin';

import listOrganizationCommon, { initialState as initListOrganizationCommon } from './organization';

import listUserOrganization, { initialState as initListUserOrganization } from './organization';

import assignBuildingUser, { initialState as initAssignBuildingUser } from './organization';

import assign, { initialState as initAssign } from './assign';
import listBuildingUnassigned, { initialState as initListBuildingUnassigned } from './organization';

import listUserUnassigned, { initialState as initListUserUnassigned } from './organization';

import listGroup, { initialState as initListGroup } from './assign';

import isUpdatePermission, { initialState as initIsUpdatePermission } from './assign';

import isUpdateName, { initialState as initIsUpdateName } from './assign';

import listTemplate, { initialState as initListTemplate } from './assign';
import isCreatedGroup, { initialState as initIsCreatedGroup } from './assign';
export const exampleInitialState = {
  auth: initialAuth,
  building: initialBuilding,
  user: initUser,
  isUpdate: initIsUpdate,
  enableAuth: initEnableAuth,
  verifyAuth: initVerifyAuth,
  disableAuth: initDisableAuth,
  dashboard: initDashboard,
  isChangePassword: initChangePassword,
  listUser: initListUser,
  isAddUser: initAddUser,
  isRemoveUser: initRemoveUser,
  listMission: initListMission,
  missionDetail: initMissionDetail,
  discardImage: initDiscardImage,
  isReset: initIsReset,
  checkToken: initCheckToken,
  confirmPassword: initConfirmPassword,
  register: initRegister,
  listMarker: initListMarker,
  buildingSummary: initBuildingSummary,
  listYearForFilter: initListYear,
  mapImage: initMapImage,
  elevationBuilding: initElevationBuilding,
  listMissionOfBuilding: initMissions,
  elevationBuildingCompare: initElevationCompare,
  missionSummary: initMissionSummary,
  statusFile: initStatusFile,
  missionElevation: initMissionElevation,
  isUpdateStatus: initUpdateStatus,
  listBuildingForSelect: initListBuildingSelect,
  isCreateMission: initCreateMission,
  contentPage: initContentPage,
  languageContent: initLanguageContent,
  languageList: initLanguage,
  isCreateBuilding: initCreateBuilding,
  isRemoveBuilding: initRemoveBuilding,
  isRemoveMission: initRemoveMission,
  notifyMission: initNotifyMission,
  isUploadBuilding: initUploadBuilding,
  formUpload: initFormUpload,
  listOrganization: initListOrganization,
  listOrganizationCommon: initListOrganizationCommon,
  listUserOrganization: initListUserOrganization,
  assignBuildingUser: initAssignBuildingUser,
  assign: initAssign,
  listBuildingUnassigned: initListBuildingUnassigned,
  listUserUnassigned: initListUserUnassigned,
  listGroup: initListGroup,
  isUpdatePermission: initIsUpdatePermission,
  isUpdateName: initIsUpdateName,
  listTemplate: initListTemplate,
  isCreatedGroup: initIsCreatedGroup
};

export default {
  auth,
  building,
  user,
  isUpdate,
  enableAuth,
  verifyAuth,
  disableAuth,
  dashboard,
  isChangePassword,
  listUser,
  isAddUser,
  isRemoveUser,
  listMission,
  missionDetail,
  discardImage,
  isReset,
  checkToken,
  confirmPassword,
  register,
  listMarker,
  loadingBar: loadingBarReducer,
  buildingSummary,
  listYearForFilter,
  mapImage,
  elevationBuilding,
  listMissionOfBuilding,
  elevationBuildingCompare,
  missionSummary,
  statusFile,
  missionElevation,
  isUpdateStatus,
  listBuildingForSelect,
  isCreateMission,
  contentPage,
  languageContent,
  languageList,
  isCreateBuilding,
  isRemoveBuilding,
  isRemoveMission,
  notifyMission,
  isUploadBuilding,
  formUpload,
  listOrganization,
  listOrganizationCommon,
  listUserOrganization,
  assignBuildingUser,
  assign,
  listBuildingUnassigned,
  listUserUnassigned,
  listGroup,
  isUpdatePermission,
  isUpdateName,
  listTemplate,
  isCreatedGroup
};
