import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useParams, } from 'react-router-dom';
import DataContext from '../DataContext';

function DataProvider({ children }) {
  const [inspections, setInspections] = useState([]);
  const { inspectionId } = useParams();

  //testgit

  const envUrl = process.env.REACT_APP_URL_COMMON_DB_API;
  const apiUrl = `${envUrl}siteTracker/generatePresigned360/?mission_guid=${inspectionId}`;
  
  useEffect(() => {
    axios
      .get(`${apiUrl}`)
      .then((response) => {
        setInspections(response.data);
        console.log("data", response.data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    // setInspections(inspectionsData);
  }, []);

  // Wrap the value in useMemo to memoize the data value
  const contextValue = useMemo(() => ({ inspections }), [inspections]);

  return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
}

DataProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default DataProvider;
