import isArray from 'lodash/isArray';

export const initialState = {
  listOrganizationCommon: {
    data: {},
    loading: true
  },
  listUserOrganization: {
    data: {},
    loading: true
  },
  assignBuildingUser: {
    data: {},
    loading: true
  },

  listBuildingUnassigned: {
    data: [],
    loading: true
  },
  listUserUnassigned: {
    data: {},
    loading: true
  },
  reportByClient: {
    data: {},
    loading: true
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_BUILDING_BY_ORGANIZATION_REQUEST':
      return {
        ...state,
        listBuildingOrganization: { ...initialState.listBuildingOrganization }
      };
    case 'GET_BUILDING_BY_ORGANIZATION_SUCCESS': {
      return {
        ...state,
        listBuildingOrganization: {
          data: action.payload,
          loading: false
        }
      };
    }
    case 'GET_ORGANIZATION_BUCKET_REQUEST':
      return {
        ...state,
        organizationBucket: { ...initialState.organizationBucket }
      };
    case 'GET_ORGANIZATION_BUCKET_SUCCESS': {
      return {
        ...state,
        organizationBucket: {
          data: action.payload,
          loading: false
        }
      };
    }
    case 'GET_ALL_ORGANIZATION_REQUEST':
      return {
        ...state,
        listOrganizationCommon: { ...initialState.listOrganizationCommon }
      };
    case 'GET_ALL_ORGANIZATION_SUCCESS': {
      return {
        ...state,
        listOrganizationCommon: {
          data: action.payload,
          loading: false
        }
      };
    }
    case 'LIST_USER_COMMON_REQUEST':
      return {
        ...state,
        listUserOrganization: { ...initialState.listUserOrganization }
      };
    case 'LIST_USER_COMMON_SUCCESS': {
      return {
        ...state,
        listUserOrganization: {
          data: action.payload,
          loading: false
        }
      };
    }
    case 'GET_REPORT_BY_CLIENT_REQUEST':
      return {
        ...state,
        reportByClient: { ...state.reportByClient, loading: true }
      };
    case 'GET_REPORT_BY_CLIENT_SUCCESS': {
      return {
        ...state,
        reportByClient: {
          data: action.payload,
          loading: false
        }
      };
    }
    case 'ASSIGN_BUILDING_USER_REQUEST':
      return {
        ...state,
        assignBuildingUser: { ...initialState.assignBuildingUser }
      };
    case 'ASSIGN_BUILDING_USER_SUCCESS': {
      return {
        ...state,
        assignBuildingUser: { data: action.payload, loading: false }
      };
    }

    case 'GET_TOTAL_INSPECTION_REQUEST':
      return {
        ...state,
        listTotalInspection: { ...initialState.listTotalInspection }
      };
    case 'GET_TOTAL_INSPECTION_SUCCESS': {
      return {
        ...state,
        listTotalInspection: { data: action.payload, loading: false }
      };
    }
    case 'GET_BUILDING_UNASSIGNED_REQUEST':
      return {
        ...state,
        listBuildingUnassigned: { ...initialState.listBuildingUnassigned }
      };
    case 'GET_BUILDING_UNASSIGNED_SUCCESS': {
      if (isArray(action.payload.data)) {
        return {
          ...state,
          listBuildingUnassigned: {
            data: action.payload,
            loading: false
          }
        };
      }
      return {
        ...state
      };
    }
    case 'LIST_USER_UNASSIGNED_REQUEST':
      return {
        ...state,
        listUserUnassigned: { ...initialState.listUserUnassigned }
      };
    case 'LIST_USER_UNASSIGNED_SUCCESS': {
      return {
        ...state,
        listUserUnassigned: {
          data: action.payload,
          loading: false
        }
      };
    }
    default:
      return state;
  }
};
