/**
 * @author Tran Trung
 * @copyright 06/06/2019 Kyanon Digital
 */
import { fork } from 'redux-saga/effects';
import es6promise from 'es6-promise';
import 'isomorphic-unfetch';

import middleware from './middleware';
import auth from './auth';
import profile from './profile';
import mission from './mission';
es6promise.polyfill();

export default function* rootSaga() {
  yield fork(middleware);
  yield fork(auth);
  yield fork(profile);
  yield fork(mission);
}
