export const initialState = {
  listUser: {},
  isAddUser: {},
  isRemoveUser: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'LIST_USER_REQUEST':
      return { ...state, listUser: { ...initialState.listUser } };
    case 'LIST_USER_SUCCESS': {
      return { ...state, listUser: action.payload };
    }
    case 'INVITE_USER_REQUEST':
      return { ...state, isAddUser: { ...initialState.isAddUser } };
    case 'INVITE_USER_SUCCESS': {
      return { ...state, isAddUser: action.payload };
    }
    case 'REMOVE_USER_REQUEST':
      return { ...state, isRemoveUser: { ...initialState.isRemoveUser } };
    case 'REMOVE_USER_SUCCESS': {
      return { ...state, isRemoveUser: action.payload };
    }
    default:
      return state;
  }
};
