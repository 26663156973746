/**
 * @author Tran Trung
 * @copyright 06/06/2019 Kyanon Digital
 */

import { takeEvery, put } from 'redux-saga/effects';

import { UPDATE_STORE } from 'redux/action/type.js';

function* updateStore(action) {
  if (action.type === UPDATE_STORE) {
    const { type, afterSuccess, user } = action.payload;
    if (type) {
      yield put({ type: type, payload: user });
    }
    if (typeof afterSuccess === 'function') {
      afterSuccess(user);
    }
  }
}
export default function* () {
  yield takeEvery('*', updateStore);
}
